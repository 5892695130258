import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
	Container,
	Row,
	Col,
	Button,
	Modal,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
	CardBody,
} from "reactstrap"

import { ToastContainer, toast } from "react-toastify";
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import PlatformList from "./PlatformList";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import 'react-toastify/dist/ReactToastify.css';

class Platform extends Component {
	constructor(props) {
		super(props)
		this.state = {
			platform: [],
			loading: false,
			modal_standard_create: false,
			modal_standard_edit: false,
			modal_standard_token: false,
			accessToken: "",
			refreshToken: "",
			userInfo: {},
			name: "",
			email: "",
			username: "",
			password: "",
			showPassword: false
		}

		this.create_platform = this.create_platform.bind(this);
		this.edit_platform = this.edit_platform.bind(this);
		this.token_platform = this.token_platform.bind(this);
		this.delete_platform = this.delete_platform.bind(this);
	}

	notify_info = (message) => toast.info(`${message}`);
	notify_error = (message) => toast.error(`${message}`);
	notify_success = (message) => toast.success(`${message}`);

	handleClick = (id, status) => {
		console.log(id, status);
	}

	getBearToken = () => {
		const BToken = localStorage.getItem("veeperAuthToken");
		return JSON.parse(BToken);
	}

	togglePasswordVisibility = () => {
		this.setState((prevShowPassword) => ({
			showPassword: !prevShowPassword.showPassword
		}));
	};

	async componentWillMount() {
		try {
			const BearerToken = this.getBearToken();
			$("#loadingIcon").text("Loading ...").css('color', 'red');

			const response = await fetch(`${API_URL}${url.GET_PLATFORM}`, {
				method: 'get',
				headers: {
					'Authorization': `${BearerToken}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			});
			const results = await response.json();
			if (response.ok) {
				const platform_list = results.data.map(single_platform => ({
					...single_platform,
					action: (<span>
						<Button
							color="success"
							onClick={() => this.edit_platform(single_platform.id, single_platform.name, single_platform.email, single_platform.username)}
							className="btn btn-primary waves-effect waves-light"
							data-toggle="modal"
							data-target="#myModal"
						> Edit </Button>
						&nbsp;&nbsp;
						<Button
							color="primary"
							onClick={() => this.token_platform(single_platform.email)}
							className="btn btn-primary waves-effect waves-light"
							data-toggle="modal"
							data-target="#myModal"
						> Token </Button>
						&nbsp;&nbsp;
						<Button
							color="danger"
							onClick={() => this.delete_platform(single_platform.id)}
							className="btn btn-danger waves-effect waves-light"
							data-toggle="modal"
							data-target="#myModal"
						> Delete </Button>
					</span>)
				}));
				const data = {
					columns: [
						{
							label: "Platform Name",
							field: "name",
							sort: "asc",
							width: 150,
						},
						{
							label: "Email",
							field: "email",
							sort: "asc",
							width: 150,
						},

						{
							label: "Contact Name",
							field: "username",
							sort: "asc",
							width: 270,
						},
						{
							label: "Action",
							field: "action",
							sort: "disabled",
							width: 300,
						}
					],
					rows: platform_list
				}
				this.setState({ platform: data, loading: true });
				this.notify_success("Platform List Loaded successfully");
			} else if (response.status === 401) {
				this.notify_error("Unauthorized: Please check your network connection.");
				// redirect to login page
				window.location.href = "/logout";
			} else if (response.status === 500) {
				this.notify_error("Server Error: Please check your network connection.");
			} else {
				this.notify_error("Please check your network connection.");
			}
		} catch (err) {
			this.notify_error("Please check your network connection.");
		}
	}

	componentDidMount() {
		setTimeout(() => this.setState({ subscribemodal: false }), 2000);

	}

	create_platform = () => {
		// clear state fdata 
		this.setState(prevState => ({
			userInfo: {},
			modal_standard_create: !prevState.modal_standard_create,
		}))
	}

	edit_platform = (id, name, email, username) => {
		console.log("Edit Platform");
		this.setState(prevState => ({
			userInfo: {
				id: id,
				name: name,
				email: email,
				username: username
			},
			modal_standard_edit: !prevState.modal_standard_edit,
		}))
	}

	token_platform = (email) => {
		const BearerToken = this.getBearToken();
		fetch(`${API_URL}${url.POST_TOKEN}`, {
			method: 'post',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"email": email
			})
		})
			.then(res => res.json())
			.then(results => {
				console.log(results.data);
				this.setState(prevState => ({
					modal_standard_token: !prevState.modal_standard_token,

					accessToken: results?.data?.accessToken || "",
					refreshToken: results?.data?.refreshedToken || ""
				}))
			}).catch(err => {
				console.log(err)
			});
	}

	delete_platform = (id) => {
		let answer = window.confirm("Are you sure you want to delete this platform?");
		if (!answer) {
			return;
		}
		const BearerToken = this.getBearToken();
		fetch(`${API_URL}${url.DELETE_PLATFORM}`, {
			method: 'delete',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"id": id
			})
		})
			.then(res => res.json())
			.then(results => {
				console.log(results.data);
				this.notify_success("Platform Deleted successfully");
				window.location.reload();
			}).catch(err => {
				console.log(err)
				this.notify_error("Platform Deletion failed");
			});
	}

	handleUpdatePlatform = (e) => {
		e.preventDefault();
		const data = this.state;
		const { id, name, username, password } = data.userInfo;
		fetch(`${API_URL}${url.PUT_PLATFORM}`, {
			method: 'put',
			headers: {
				'Authorization': `${this.getBearToken()}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				id,
				name,
				username,
				password
			})
		})
			.then(res => res.json())
			.then(async results => {
				console.log(results.data);
				// refresh the page
				this.notify_success("Platform Updated successfully");
				await new Promise(r => setTimeout(r, 5000));
				window.location.reload();
			}).catch(err => {
				console.log(err)
				this.notify_error("Platform Update failed");
			});

	}

	handlePlatformSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		const { name, email, username, password } = data.userInfo;
		fetch(`${API_URL}${url.POST_REGISTER}`, {
			method: 'post',
			headers: {
				'Authorization': `${this.getBearToken()}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				name,
				email,
				username,
				password
			})
		})
			.then(res => res.json())
			.then(async results => {
				// refresh the page
				this.notify_success("Platform Created successfully");
				await new Promise(r => setTimeout(r, 5000));
				window.location.reload();
			}).catch(err => {
				console.log(err)
				this.notify_error("Platform Creation failed");
			});
	}

	handleInputChange = (e) => {
		console.log(e.target.name, e.target.value);
		this.setState({
			userInfo: {
				...this.state.userInfo,
				[e.target.name]: e.target.value
			}
		})
	}

	render() {
		const {
			loading,
			platform,
		} = this.state;

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Platform</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={this.props.t("Platform")}
							breadcrumbItem={this.props.t("Platform")}
						/>

						<ToastContainer />
						<Row>
							<div className="" style={{ "textAlign": "right", "paddingBottom": "10px" }}>
								<span className="loading"></span>
								<Button
									color="primary"
									className="btn btn-primary waves-effect waves-light"
									onClick={this.create_platform}
									data-toggle="modal"
									data-target="#myModal"
								>Create</Button>
							</div>
							<Col xl={12}>
								{loading ? <PlatformList customers={platform} /> : <p id="loadingIcon" style={{ "paddingLeft": "34px" }}>Loading ...</p>}
							</Col>

							<Modal
								isOpen={this.state.modal_standard_create}
								toggle={this.create_platform}
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0" id="myModalLabel">
										Create Platform
									</h5>
									<button
										type="button"
										onClick={() =>
											this.setState({ modal_standard_create: false })
										}
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<Card>
										<CardBody>
											<Form onSubmit={this.handlePlatformSubmit}>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-firstname-Input"
														className="col-sm-4 col-form-label"
													>
														Platform Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="name"
															placeholder="Platform Name"
															className="form-control"
															id="horizontal-firstname-Input"
															value={this.state?.userInfo?.name}
															onChange={this.handleInputChange}
															required
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-username-Input"
														className="col-sm-4 col-form-label"
													>
														Contact Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="username"
															placeholder="Contact Name"
															className="form-control"
															id="horizontal-username-Input"
															value={this.state?.userInfo?.username}
															onChange={this.handleInputChange}
															required
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-email-Input"
														className="col-sm-4 col-form-label"
													>
														Email
													</Label>
													<Col sm={8}>
														<Input
															type="email"
															name="email"
															placeholder="Email Address"
															className="form-control"
															id="horizontal-email-Input"
															value={this.state?.userInfo?.email}
															onChange={this.handleInputChange}
															required
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row justify-content-end">
													<Col sm={8}>
														<div>
															<Button
																type="submit"
																color="primary"
																className="w-md"
															>
																Submit
															</Button>
														</div>
													</Col>
												</FormGroup>
											</Form>
										</CardBody>
									</Card>
								</div>
							</Modal>

							<Modal
								isOpen={this.state.modal_standard_edit}
								toggle={this.edit_platform}
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0" id="myModalLabel">
										Edit Platform
									</h5>
									<button
										type="button"
										onClick={() =>
											this.setState({ modal_standard_edit: false })
										}
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div className="modal-body">
									<Card>
										<CardBody>
											<Form onSubmit={this.handleUpdatePlatform}>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-firstname-Input"
														className="col-sm-4 col-form-label"
													>
														Platform Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="name"
															placeholder="Platform Name"
															className="form-control"
															id="horizontal-firstname-Input"
															value={this.state?.userInfo?.name}
															onChange={this.handleInputChange}
															required
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-username-Input"
														className="col-sm-4 col-form-label"
													>
														Contact Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="username"
															placeholder="Contact Name"
															className="form-control"
															id="horizontal-username-Input"
															value={this.state?.userInfo?.username}
															onChange={this.handleInputChange}

															required
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-email-Input"
														className="col-sm-4 col-form-label"
													>
														Email
													</Label>
													<Col sm={8}>
														<Input
															type="email"
															name="email"
															placeholder="Email Address"
															className="form-control"
															id="horizontal-email-Input"
															value={this.state?.userInfo?.email}
															readOnly
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row justify-content-end">
													<Col sm={8}>
														<div>
															<Button
																type="submit"
																color="primary"
																className="w-md"
															>
																Update
															</Button>
														</div>
													</Col>
												</FormGroup>
											</Form>
										</CardBody>
									</Card>
								</div>
							</Modal>

							<Modal
								isOpen={this.state.modal_standard_token}
								toggle={this.token_platform}
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0" id="myModalLabel">
										Platform Token
									</h5>
									<button
										type="button"
										onClick={() =>
											this.setState({ modal_standard_token: false })
										}
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<h5>Access Token</h5>
									<p style={{ wordWrap: "break-word" }}>
										{this.state.accessToken}
									</p>
									<h5>Refresh Token</h5>
									<p style={{ wordWrap: "break-word" }}>
										{this.state.refreshToken}
									</p>
								</div>
								<div className="modal-footer">
								</div>
							</Modal>
						</Row>
					</Container>
				</div>

			</React.Fragment >
		)

	}
}

Platform.propTypes = {
	t: PropTypes.any
}

export default withTranslation()(Platform)
