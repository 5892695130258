import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { Component } from "react"
import {
    Container,
    Button,
    Input,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardColumns,
    Row,
    FormGroup,
    Label
} from "reactstrap"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";

import Breadcrumbs from "../../components/Common/Breadcrumb"

class PlanInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_domain: "",
            loading: false,
            store_details: {},
            store_status: "",
            message: "",
            alert: "success",
            coupon: '',

            Coupons: 0,
            totalCoupons: 0,
            newTrialDays: 1,
            isFreePlan: false,
            is_active: true
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }


    notify_info = (message) => toast.info(`${message}`);
    notify_error = (message) => toast.error(`${message}`);
    notify_success = (message) => toast.success(`${message}`);
    getBearToken = () => {
        const BToken = localStorage.getItem("veeperAuthToken");
        return JSON.parse(BToken);
    }

    componentDidMount() {
        const { data } = this.props.location;
        if (!data) {
            return window.location.href = '/stores';
        }
        const { store_domain, store_details, store_status } = data;
        console.log({ is_active: store_details.is_active })
        const setState = {
            store_domain,
            store_status,
            store_details,
            isFreePlan: store_details?.is_free_subscription || false,
            is_active: store_details.is_active,
        }
        if (store_details?.current_period_start) {
            const current_period_start = new Date(store_details?.current_period_start).getTime();
            const now = Date.now();
            // day difference 
            let trialDays = Math.floor((current_period_start - now) / (1000 * 60 * 60 * 24));
            if (trialDays <= 0) {
                trialDays = 0;
            }

            this.setState({
                ...setState,
                store_details: { ...store_details, trialDays },
            });
        } else {
            this.setState({
                ...setState,
            });
        }

        // set free plan


        setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    }
    updateItem = (e) => {
        // e.preventDefault();
        this.setState({
            store_domain: e.store_domain,
            type: e.type,
        })
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    updatePlanInfo = async (param) => {
        const {
            additionalOrdersPrice,
            newTrialDays,
            store_domain,
            store_details
        } = this.state;
        const BearerToken = this.getBearToken();
        if (param === "trialDays" && newTrialDays <= 0) {
            return;
        }
        if (param === 'trialDays') {
            const response = await fetch(`${API_URL}${url.EXTEND_TRIAL_DAYS}`, {
                method: 'put',
                headers: {
                    'Authorization': `${BearerToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shop: store_domain,
                    trial_days: newTrialDays,
                    subscription_id: store_details.subscription_id,
                })
            })
            const data = await response.json();
            if (data?.error) {
                this.setState({ alert: "danger", message: data.error })
            } else {
                this.setState(prevState => ({
                    store_details: {
                        ...prevState.store_details,
                        trialDays: parseInt(newTrialDays) + parseInt(prevState.store_details.trialDays),
                    }
                }))
                this.notify_success("Plan info updated successfully");
            }
        }

        const response = await fetch(`${API_URL}${url.EDIT_USAGE_CHARGES}`, {
            method: 'put',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: store_domain,
                usage_charges: additionalOrdersPrice,
            })
        })
        const data = await response.json();
        if (data?.error) {
            this.setState({ alert: "danger", message: data.error })
        } else {
            this.setState(prevState => ({
                store_details: {
                    ...prevState.store_details,
                    plan_info: {
                        ...prevState.store_details?.plan_info,
                        additionalOrdersPrice,
                    }
                }
            }));
            this.notify_success("Plan info updated successfully");
        }
    }
    handleVeeperDisable = async () => {
        // show alert
        let answer = window.confirm("Are you sure you want to disable veeper?");
        if (!answer) {
            return;
        }
        // update plan
        const { store_domain } = this.state;
        const message = this.state.is_active ? "Veeper disabled successfully" : "Veeper enabled successfully";
        console.log({ is_active: !this.state.is_active })
        await this.updateStoreStatus(store_domain, undefined, !this.state.is_active, message);

        // redirect to stores page
        if (this.state.is_active) {
            window.location.href = "/uninstalled-stores";
        } else {
            window.location.href = "/stores";
        }
    }

    handleChangeCheckbox = () => {
        // show alert
        let answer = window.confirm("Are you sure you want to change?");
        if (!answer) {
            return;
        }
        this.setState({
            isFreePlan: this.state.isFreePlan ? false : true
        });

        // update plan
        const { store_domain } = this.state;
        this.updateStoreStatus(store_domain, !this.state.isFreePlan, undefined, "Plan info updated successfully");
    }

    updateStoreStatus = async (store_domain, is_free_subscription, is_active, successMessage) => {
        const body = is_free_subscription === undefined ? { store_domain, is_active, install_status: is_active ? 3 : 0 } : { store_domain, is_free_subscription };
        const BearerToken = this.getBearToken();
        const response = await fetch(`${API_URL}${url.PUT_UPDATE_STORE}`, {
            method: 'put',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        const data = await response.json();
        console.log(data)
        if (data?.error) {
            this.setState({ alert: "danger", message: data.error });
        } else {
            this.notify_success(successMessage);
        }
    }

    render() {
        const { alert, isFreePlan, is_active, message, loading, store_details, store_status, additionalOrdersPrice = store_details?.plan_info?.additionalOrdersPrice || '', trialDays = store_details?.trialDays || 0, newTrialDays = 1 } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | Subscription Plan Info </title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={("Subscription Plan Info")}
                            breadcrumbItem={("Subscription Information")}
                        />
                        <ToastContainer />
                        <Row>
                            <Col md={12}>
                                {loading && <div class={`alert alert-${alert}`} role="alert">
                                    {message}
                                </div>}
                                <Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button className="btn btn-dark">
                                        <i className="fa fa-arrow-left"></i> Store Details
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col md={8}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="text-center mb-4" ><h3>Current Plan Info</h3></CardTitle>
                                        <CardColumns>
                                            <h5 className="mb-3">{store_details.plan || ''}</h5>
                                            <h5 className="mb-3" style={{ color: store_details.plan_status === 'ACTIVE' ? 'green' : 'red', fontWeight: 'bold' }}>{store_details.plan_status || ''}</h5>
                                            <h5 className="mb-3">Price:  {store_details?.plan_info?.price || ''}</h5>
                                        </CardColumns>

                                        <CardColumns>
                                            <h5 className="mb-3">{store_details?.plan_info?.interval || ''}</h5>
                                            <h5 className="mb-3">Orders:  {store_details?.plan_info?.orders || ''}</h5>
                                            <h5 className="mb-3">Additional Orders: {store_details?.plan_info?.additionalOrders || ''}</h5>
                                        </CardColumns>

                                        <CardColumns>
                                            <h5 className="mb-3">Additional Orders Price: {store_details?.plan_info?.additionalOrdersPrice || ''}</h5>
                                            <h5 className="mb-3">Trial Days: {store_details?.trialDays || ''}</h5>
                                        </CardColumns>
                                        <hr />
                                        {/* Update Plan Info center */}
                                        <CardTitle className="text-center mb-3" >Update Plan Info</CardTitle>
                                        <CardColumns>
                                            <h5 className="mb-3">Additional Orders Price: <Input name="additionalOrdersPrice" type="text" value={additionalOrdersPrice} onChange={this.handleInputChange} /></h5>
                                            <Button className="btn btn-primary" onClick={() => this.updatePlanInfo("additionalOrdersPrice")}>Update</Button>
                                        </CardColumns>
                                        <hr />
                                        <CardColumns>
                                            <h5 className="mb-3">Trial Days: <Input name="newTrialDays" type="text" value={newTrialDays} onChange={this.handleInputChange} /></h5>
                                            <Button className="btn btn-primary" onClick={() => this.updatePlanInfo("trialDays")} disabled={trialDays <= 0}>Update</Button>
                                        </CardColumns>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="text-center mb-4" ><h3>Switching to Free Plan</h3></CardTitle>
                                        <CardColumns>
                                            <FormGroup className="form-check form-switch">
                                                <Label
                                                    htmlFor="formSwitchChecked"
                                                    className="col-md-12 col-form-label"
                                                    style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}

                                                >
                                                    Is Free Plan
                                                </Label>
                                                <Input
                                                    type="checkbox"
                                                    name="is_free_plan"
                                                    className="form-control form-switch"
                                                    id="formSwitchChecked"
                                                    onChange={this.handleChangeCheckbox}
                                                    checked={isFreePlan}
                                                    style={{ marginLeft: "60%", marginTop: "10px", marginBottom: "10px" }}

                                                />
                                            </FormGroup>
                                        </CardColumns>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="text-center mb-4" ><h3 style={{ color: is_active ? "red" : "green" }}>Disable Veeper</h3></CardTitle>
                                        <CardColumns>
                                            <FormGroup className="form-check form-switch">
                                                <Label
                                                    htmlFor="formVeeperSwitchChecked"
                                                    className="col-md-12 col-form-label"
                                                    style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}
                                                >
                                                    Active
                                                </Label>
                                                <Input
                                                    type="checkbox"
                                                    name="is_disabled"
                                                    className="form-control form-switch"
                                                    id="formVeeperSwitchChecked"
                                                    onChange={this.handleVeeperDisable}
                                                    checked={is_active}
                                                    style={{ marginLeft: "60%", marginTop: "10px", marginBottom: "10px" }}

                                                />
                                            </FormGroup>
                                        </CardColumns>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

PlanInfo.propTypes = {
    t: PropTypes.any
}
export default PlanInfo
