import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

import Stores from "../pages/Stores/index"
// import Shoppers from "pages/Shoppers/index"
import Platform from "pages/Platform/index"
import CampaignChargeEdit from "pages/Stores/campaignChargeEdit"
import Dashboard from "../pages/Dashboard/index"
import ChargeEdit from "pages/Stores/ChargeEdit"
import SettingEdit from "pages/Stores/CommonSettingsEdit"
import PlacementEdit from "pages/Stores/PlacementEdit"
import UploadRosterCSV from "pages/Stores/UploadRosterCSV"
import DiscountTypeEdit from "pages/Stores/DiscountTypeEdit"
import MerchantsDetails from "../pages/Stores/merchantDetails"
import PriceSelectorEdit from "pages/Stores/PriceSelectorEdit"
import UninstalledStores from "../pages/Stores/uninstalledStores"
import CampaignMetafields from "pages/Stores/CampaignMetafieldsEdit"
import StoresHandle from "pages/StoreHandler/index"
import BlockedCouponView from "pages/Stores/BlockedCouponView"
import PlanInfo from "pages/Stores/PlanInfo"


//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

const authProtectedRoutes = [
  { path: "/stores", component: Stores },
  // { path: "/shoppers", component: Shoppers },
  { path: "/dashboard", component: Dashboard },
  { path: "/placement-edit", component: PlacementEdit },
  { path: "/usage-charges-edit", component: ChargeEdit },
  { path: "/common-setting-edit", component: SettingEdit },
  { path: "/campaign-charge-edit", component: CampaignChargeEdit },
  { path: "/blocked-coupon", component: BlockedCouponView },
  { path: "/plan-info", component: PlanInfo },
  { path: "/upload-roster-csv", component: UploadRosterCSV },
  { path: "/merchant-details", component: MerchantsDetails },
  { path: "/discount-type-edit", component: DiscountTypeEdit },
  { path: "/uninstalled-stores", component: UninstalledStores },
  { path: "/price-selector-edit", component: PriceSelectorEdit },
  { path: "/campaign-metafields-edit", component: CampaignMetafields },

  // Platform
  { path: "/platform", component: Platform },
  { path: "/stores-handle", component: StoresHandle },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/profile", component: Register },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  { path: "/", exact: true, component: () => (localStorage.getItem("authUser")) ? <Redirect to="/dashboard" /> : <Redirect to="/login" /> },
]

export { authProtectedRoutes, publicRoutes }
