import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Col,
    Container,
    Row,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { formatToShortHand } from "../../helpers/common_helper"

import BasicCountInfo from "./BasicCountInfo"
import MerchantsList from "../Stores/MerchantsList"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify";

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            revenue: 0,
            totalStores: 0,
            MRR: 0,
            ARR: 0,
            legacyPlan: 0,
            starterPlan: 0,
            growthPlan: 0,
            advancedPlan: 0,
            premiumPlan: 0,
            enterprisePlan: 0,
            legacyPlanPrice: 0,
            growthPlanPrice: 0,
            starterPlanPrice: 0,
            premiumPlanPrice: 0,
            advancedPlanPrice: 0,
            enterprisePlanPrice: 0,
            totalHeadlessStores: 0,
            loading: false,
            lastInstalledStores: [],
            installedStores: 0,
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    notify_info = (message) => toast.info(`${message}`);
    notify_error = (message) => toast.error(`${message}`);
    notify_success = (message) => toast.success(`${message}`);

    formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
    };
    async componentWillMount() {
        try {
            const BToken = localStorage.getItem("veeperAuthToken");
            const BearerToken = JSON.parse(BToken);
            // console.log(BearerToken)
            $("#loadingIcon").text("Loading ...").css('color', 'red');
            const [dashboard, lastInstalledStores] = await Promise.all([
                fetch(`${API_URL}${url.GET_DASHBOARD}`, {
                    method: 'get',
                    headers: {
                        'Authorization': `${BearerToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }),
                fetch(`${API_URL}${url.GET_LAST_INSTALLED_STORES}`, {
                    method: 'get',
                    headers: {
                        'Authorization': `${BearerToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
            ]);
            const dashboard_data = await dashboard.json();
            if (dashboard.ok) {
                this.setState({
                    ...dashboard_data,
                    loading: true
                });

            } else if (dashboard.status === 401) {
                this.notify_error("Unauthorized: Please check your network connection.");
                // redirect to login page
                window.location.href = "/logout";
            } else if (dashboard.status === 500) {
                this.notify_error("Server Error: Please check your network connection.");
            } else {
                this.notify_error("Please check your network connection.");
            }
            const last_installed_stores = await lastInstalledStores.json();
            if (lastInstalledStores.ok) {
                const stores = last_installed_stores?.data?.map((store, index) => ({
                    sn: index + 1,
                    store_name: store.store_name,
                    store_domain: store.store_domain,
                    plan: store.plan,
                    created: this.formatDate(store.created_at),
                    status: store?.is_active ? <span className="btn btn-primary btn-sm">ACTIVE</span> : <span className="btn btn-danger btn-sm">INACTIVE</span>,
                    trial: store?.is_trial ? 'Trial' : 'Not Trial',
                }))
                const table_records = {
                    columns: [
                        {
                            label: "SN",
                            field: "sn",
                            sort: "asc",
                            width: 50,
                        },
                        {
                            label: "Name",
                            field: "store_name",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Domain",
                            field: "store_domain",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Plan",
                            field: "plan",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Status",
                            field: "status",
                            width: 100,
                        },
                        {
                            label: "Trial",
                            field: "trial",
                            width: 100,
                        },
                        {
                            label: "Installed",
                            field: "created",
                            width: 100,
                        },

                    ],
                    rows: stores
                }
                this.setState({
                    loading: true,
                    installedStores: last_installed_stores?.data?.length || 0,
                    lastInstalledStores: table_records,
                })
                this.notify_success("Dashboard Loaded successfully");
            } else if (lastInstalledStores.status === 401) {
                this.notify_error("Unauthorized: Please check your network connection.");
                // redirect to login page
                window.location.href = "/logout";
            } else if (lastInstalledStores.status === 500) {
                this.notify_error("Server Error: Please check your network connection.");
            } else {
                this.notify_error("Please check your network connection.");
            }
        } catch (err) {
            this.notify_error("Please check your network connection.");
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);

    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    render() {
        const {
            revenue = 0,
            totalStores = 0,
            MRR = 0,
            ARR = 0,
            legacyPlan = 0,
            starterPlan = 0,
            growthPlan = 0,
            advancedPlan = 0,
            premiumPlan = 0,
            enterprisePlan = 0,
            legacyPlanPrice = 0,
            growthPlanPrice = 0,
            starterPlanPrice = 0,
            premiumPlanPrice = 0,
            advancedPlanPrice = 0,
            enterprisePlanPrice = 0,
            totalHeadlessStores = 0,
            lastInstalledStores,
            installedStores
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Home</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Home")}
                            breadcrumbItem={this.props.t("How We're doing")}
                        />
                        <ToastContainer />
                        <Row>
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(MRR)} title="MRR" />
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(ARR)} title="ARR" />
                            <BasicCountInfo store_domain="" colSize="3" data={totalStores} linkUrl="stores" title="Clients" />
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(revenue)} title="Revenue" />
                            <BasicCountInfo store_domain="" colSize="3" data={totalHeadlessStores} title="Headless Stores" />
                            <BasicCountInfo store_domain="" colSize="3" data={legacyPlan} price={formatToShortHand(legacyPlanPrice)} secondParams='yes' title="Legacy Plan" isClick="true" />
                            <BasicCountInfo store_domain="" colSize="3" data={starterPlan} price={formatToShortHand(starterPlanPrice)} secondParams='yes' title="Starter Plan" isClick="true" />
                            <BasicCountInfo store_domain="" colSize="3" data={growthPlan} price={formatToShortHand(growthPlanPrice)} secondParams='yes' title="Growth Plan" isClick="true" />
                            <BasicCountInfo store_domain="" colSize="3" data={advancedPlan} price={formatToShortHand(advancedPlanPrice)} secondParams='yes' title="Advanced Plan" isClick="true" />
                            <BasicCountInfo store_domain="" colSize="3" data={premiumPlan} price={formatToShortHand(premiumPlanPrice)} secondParams='yes' title="Premium Plan" isClick="true" />
                            <BasicCountInfo store_domain="" colSize="3" data={enterprisePlan} price={formatToShortHand(enterprisePlanPrice)} secondParams='yes' title="Enterprise Plan" isClick="true" />
                        </Row>

                        <Row>
                            <div id="loadingIcon"></div>
                            <Col xl={12}>
                                <MerchantsList
                                    data={lastInstalledStores}
                                    default_entries={10}
                                    displayEntries={false}
                                    searching={false}
                                    title={` Last ${installedStores} Installed Stores`}
                                    paging={false}
                                />
                            </Col>
                        </Row>

                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

Dashboard.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Dashboard)
